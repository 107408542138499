/**
 * List of Contract statuses.
 */
class ResearchFocusStatus {
    static get values() {
      return {   
        approved: 'approved',
        revise: 'revise',
        proposed: 'proposed',
      };
    }
  }
  
  module.exports = ResearchFocusStatus;
  