<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="entities.researchFocus.menu"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="entities.researchFocus.list.title"></app-i18n>
      </h1>

      <app-research-focus-list-toolbar></app-research-focus-list-toolbar>
      <app-research-focus-list-filter></app-research-focus-list-filter>
      <app-research-focus-list-table></app-research-focus-list-table>
    </div>
  </div>
</template>

<script>
import ResearchFocusListFilter from '@/modules/research-focus/components/research-focus-list-filter.vue';
import ResearchFocusListTable from '@/modules/research-focus/components/research-focus-list-table.vue';
import ResearchFocusListToolbar from '@/modules/research-focus/components/research-focus-list-toolbar.vue';

export default {
  name: 'app-research-focus-list-page',

  components: {
    [ResearchFocusListFilter.name]: ResearchFocusListFilter,
    [ResearchFocusListTable.name]: ResearchFocusListTable,
    [ResearchFocusListToolbar.name]: ResearchFocusListToolbar,
  },
};
</script>

<style>
</style>
